<template>
  <div>
    <v-dialog
      v-model="show"
      content-class="modal-create-bot-component"
      width="650"
      persistent
    >
      <v-card>
        <v-card :elevation="4" tile>
          <v-card-title class="pa-2 d-flex justify-space-between">
            <span class="title font-weight-bold">Create Bot</span>
            <v-btn icon @click="show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>
        <v-card-text class="pa-0 relative">
          <LoadingComponent v-if="isLoading" overlay />
          <div>
            <div class="pa-5 flex-6">
              <v-form ref="create_bot" :model="form">
                <div class="d-flex">
                  <div class="avatar-container pointer">
                    <v-avatar size="30" class="edit-avatar">
                      <!-- <fa-icon icon="pen" /> -->
                      <v-file-input
                        :color="'#FFFFFF'"
                        flat
                        prepend-icon="mdi-pencil"
                        hide-input
                        :rules="rules"
                        accept="image/png, image/jpeg, image/bmp"
                        class="pt-0 mt-0"
                        @change="handleImageUpload"
                      ></v-file-input>
                    </v-avatar>

                    <div v-if="!form.icon">
                      <v-img
                        :src="previewImage"
                        width="60"
                        height="60"
                        v-if="previewImage"
                        contain
                      ></v-img>
                      <v-avatar rounded size="60" color="#E9EBEE" v-else>
                        <v-icon size="60" color="grey" dark>
                          mdi-account
                        </v-icon>
                      </v-avatar>
                    </div>
                    <div v-else>
                      <v-img
                        :src="form.icon"
                        width="60"
                        height="60"
                        contain
                      ></v-img>
                    </div>
                  </div>
                  <div class="profile-info">
                    <v-text-field
                      dense
                      hide-details
                      class="flex-grow-1 mb-0"
                      label="Bot Name"
                      outlined
                      style="width: 200px"
                      v-model="form.name"
                    ></v-text-field>
                  </div>
                </div>

                <div class="color-settings pt-5 mb-5">
                  <v-row>
                    <v-col cols="12" lg="6" md="6">
                      <div class="d-flex align-center">
                        <div
                          class="color-picker"
                          :style="`background:${form.ai_text_color}`"
                          @click.stop="aiTextColorShow = !aiTextColorShow"
                        ></div>
                        <div class="ml-2">AI Text Color</div>
                      </div>
                      <v-color-picker
                        class="color-picker-popup"
                        v-show="aiTextColorShow"
                        v-model="form.ai_text_color"
                        flat
                      ></v-color-picker>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                      <div class="d-flex align-center">
                        <div
                          class="color-picker"
                          :style="`background:${form.ai_bg_color}`"
                          @click.stop="aiBgColorShow = !aiBgColorShow"
                        ></div>
                        <div class="ml-2">AI Background Color</div>
                      </div>
                      <v-color-picker
                        class="color-picker-popup"
                        v-show="aiBgColorShow"
                        v-model="form.ai_bg_color"
                        flat
                      ></v-color-picker>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" lg="6" md="6">
                      <div class="d-flex align-center">
                        <div
                          class="color-picker"
                          :style="`background:${form.user_text_color}`"
                          @click.stop="userTextColorShow = !userTextColorShow"
                        ></div>
                        <div class="ml-2">User Text Color</div>
                      </div>
                      <v-color-picker
                        class="color-picker-popup"
                        v-show="userTextColorShow"
                        v-model="form.user_text_color"
                        flat
                      ></v-color-picker>
                    </v-col>
                    <v-col cols="12" lg="6" md="6">
                      <div class="d-flex align-center">
                        <div
                          class="color-picker"
                          :style="`background:${form.user_bg_color}`"
                          @click.stop="userBgColorShow = !userBgColorShow"
                        ></div>
                        <div class="ml-2">User Background Color</div>
                      </div>
                      <v-color-picker
                        class="color-picker-popup"
                        v-show="userBgColorShow"
                        v-model="form.user_bg_color"
                        flat
                      ></v-color-picker>
                    </v-col>
                    <v-col cols="12">
                      <div class="description">
                        <v-textarea
                          dense
                          hide-details
                          class="mb-0"
                          label="Bot Description"
                          outlined
                          rows="2"
                          v-model="form.description"
                        ></v-textarea>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <!-- <v-divider class="my-6"></v-divider> -->
                <div class="greeting-msg">
                  <v-text-field
                    dense
                    hide-details
                    class="mb-0"
                    label="Greeting Message"
                    outlined
                    v-model="form.greeting_message"
                  ></v-text-field>
                </div>
              </v-form>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <div class="pb-2">
            <v-btn text @click="show = false" plain> Cancel </v-btn>
            <v-btn color="primary" :loading="isSaving" @click="submit"
              >Create</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import LoadingComponent from "@/components/helpers/Loading.vue";
import Utils from "@/helpers/utils";

export default {
  name: "modal-about",

  components: {
    LoadingComponent,
  },

  data() {
    return {
      isLoading: false,
      isSaving: false,
      rules: [(value) => !!value || "Please select an image."],
      form: {
        icon: "",
        name: "Chatbot",
        user_text_color: "#FFFFFF",
        ai_text_color: "#FFFFFF",
        user_bg_color: "#4285F3",
        ai_bg_color: "#27272A",
        description: "",
        greeting_message: "Hi! Ask me anything!",
      },
      previewImage: null,
      aiTextColorShow: false,
      aiBgColorShow: false,
      userTextColorShow: false,
      userBgColorShow: false,
      toneShow: false,
    };
  },

  methods: {
    ...mapMutations("modal", ["setShowCreateBot"]),
    ...mapActions("bots", ["createBot", "getBots"]),

    handleImageUpload(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
          this.form.icon = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        if (this.form.icon == null) {
          this.previewImage = null;
        } else {
          this.previewImage = this.form.icon;
        }
      }
    },

    submit() {
      this.isSaving = true;
      this.createBot(this.form).then(
        async (res) => {
          globalMessageHandler("Successfully Created.");
          this.isSaving = false;
          this.getBots();
          this.show = false;
        },
        (err) => {
          globalErrorHandler(err, "Error Creating Bot!");
          this.isSaving = false;
        }
      );
    },
  },

  computed: {
    ...mapGetters("modal", ["showCreateBot"]),
    show: {
      get() {
        return this.showCreateBot;
      },
      set(v) {
        this.setShowCreateBot(v);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-create-bot-component {
  max-height: 100% !important;
}

.avatar-container {
  display: inline-block;
  position: relative;
  border: 1px solid rgba(44, 62, 80, 0.2);
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;

  img {
    object-fit: cover;
  }

  .edit-avatar {
    top: -12px;
    right: -12px;
    position: absolute;
    color: white;
    background: #fff;
    border: 1px solid lightgray;
    padding-left: 3px;
    z-index: 1;
  }
}

button.v-icon.notranslate.v-icon--link.mdi.mdi-pencil.theme--light {
  color: #fff !important;
}

.profile-info {
  display: inline-block;
  vertical-align: top;
  margin-left: 23px;
}

.color-settings {
  .color-picker {
    border: 1px solid #272727;
    border-radius: 5px;
    position: relative;
    height: 30px;
    width: 30px;
  }

  .color-picker-popup {
    margin-top: 5px;
    position: absolute;
    width: 300px;
    border-radius: 6px;
    background: #fff !important;
    z-index: 1;
    border: 1px solid #00000033;
  }
}

.v-select-list {
  margin-top: 40px;
}
</style>
