import { getDelegatedAccounts, getRoles } from "@/routes";

export default {
  namespaced: true,
  state: {
    roles: [],
    delegatedAccounts: {
      data: [],
    },
    userData: {},
  },
  getters: {
    roles: (state) => state.roles,
    delegatedAccounts: (state) => state.delegatedAccounts,
    userData: (state) => state.userData,
  },
  mutations: {
    SET_ROLES(state, data) {
      state.roles = data;
    },
    SET_USER_DATA(state, data) {
      state.userData = data;
    },
    SET_DELEGATED_ACCOUNTS(state, data) {
      state.delegatedAccounts.data = data;
    },
  },
  actions: {
    getRoles({ commit }) {
      return getRoles().then(
        (res) => {
          commit("SET_ROLES", res.data);
          console.log(res.data);
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
    },
    getDelegatedAccounts({ commit }, id) {
      return getDelegatedAccounts(id);
    },
  },
};
