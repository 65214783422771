import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "default",
    redirect: "/home",
  },
  {
    path: "/",
    component: () => import("@/MainLayout.vue"),
    meta: {
      pageMenu: "main",
    },
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("../views/HomeMain.vue"),
        meta: {
          auth: 0,
          pageMenu: "home",
        },
      },
      {
        path: "about",
        name: "about",
        component: () => import("../views/About.vue"),
        meta: {
          auth: 0,
          pageMenu: "about",
        },
      },

      {
        path: "privacy-policy",
        name: "privacy-policy",
        component: () => import("../views/PrivacyPolicy.vue"),
        meta: {
          auth: 0,
          pageMenu: "privacy-policy",
        },
      },

      {
        path: "terms-and-conditions",
        name: "terms-and-conditions",
        component: () => import("../views/TermsAndConditions.vue"),
        meta: {
          auth: 0,
          pageMenu: "terms-and-conditions",
        },
      },

      //new router
      {
        path: "signup",
        name: "signup",
        component: () => import("../components/auth/signup.vue"),
        meta: {
          auth: 0,
        },
      },
      {
        path: "sign-in",
        name: "sign-in",
        component: () => import("../components/auth/signin.vue"),
        meta: {
          auth: 0,
        },
      },

      //help types
      {
        path: "/dashboard/help/chatbot-url",
        name: "help-chatbot-url",
        component: () => import("../components/dashboard/help/chatbotUrl.vue"),
        meta: {
          auth: 0,
          pageMenu: "dashboard",
        },
      },
      {
        path: "/dashboard/help/embed-url",
        name: "help-embed-url",
        component: () => import("../components/dashboard/help/embedUrl.vue"),
        meta: {
          auth: 0,
          pageMenu: "dashboard",
        },
      },
      {
        path: "/dashboard/help/messenger",
        name: "help-messenger",
        component: () => import("../components/dashboard/help/messenger.vue"),
        meta: {
          auth: 0,
          pageMenu: "dashboard",
        },
      },
    ],
  },
  {
    path: "/dashboard/",
    name: "AuthDashboard",
    component: () => import("@/AuthenticatedLayout.vue"),
    meta: {
      auth: 1,
      pageMenu: "dashboard",
    },
    children: [
      //playground
      {
        path: "/dashboard/playground",
        name: "DashboardPlayground",
        component: () => import("../components/dashboard/Playground.vue"),
        meta: {
          auth: 1,
          pageMenu: "dashboard",
        },
      },
      {
        path: "/dashboard/playground/:botId",
        name: "DashboardPlayground",
        component: () => import("../components/dashboard/Playground.vue"),
        meta: {
          auth: 1,
          pageMenu: "dashboard",
        },
      },

      //notifications
      {
        path: "/dashboard/notifications",
        name: "DashboardNotifications",
        component: () => import("../components/dashboard/Notifications.vue"),
        meta: {
          auth: 1,
          pageMenu: "dashboard",
        },
      },

      //train
      {
        path: "/dashboard/manage-bots",
        name: "DashboardManageBots",
        component: () => import("../components/dashboard/Settings.vue"),
        meta: {
          auth: 1,
          pageMenu: "dashboard",
        },
      },
      {
        path: "/dashboard/manage-bots/:botId",
        name: "DashboardManageBots",
        component: () => import("../components/dashboard/Settings.vue"),
        meta: {
          auth: 1,
          pageMenu: "dashboard",
        },
      },

      {
        path: "/dashboard/train-bots",
        name: "DashboardTrainBots",
        component: () => import("../components/dashboard/Settings.vue"),
        meta: {
          auth: 1,
          pageMenu: "dashboard",
        },
      },
      {
        path: "/dashboard/train-bots/:botId",
        name: "DashboardTrainBots",
        component: () => import("../components/dashboard/Settings.vue"),
        meta: {
          auth: 1,
          pageMenu: "dashboard",
        },
      },

      //history
      {
        path: "/dashboard/history",
        name: "DashboardHistories",
        component: () => import("../components/dashboard/History.vue"),
        meta: {
          auth: 1,
          pageMenu: "dashboard",
        },
      },
      {
        path: "/dashboard/history/:botId",
        name: "DashboardHistories",
        component: () => import("../components/dashboard/History.vue"),
        meta: {
          auth: 1,
          pageMenu: "dashboard",
        },
      },
      {
        path: "/dashboard/history/:botId/messages/:messagesId",
        name: "DashboardHistories",
        component: () => import("../components/dashboard/History.vue"),
        meta: {
          auth: 1,
          pageMenu: "dashboard",
        },
      },
      //settings
      {
        path: "/dashboard/settings",
        name: "DashboardSettings",
        component: () => import("../components/dashboard/Settings.vue"),
        meta: {
          auth: 1,
          pageMenu: "dashboard",
        },
      },
      {
        path: "/dashboard/settings/:botId",
        name: "DashboardSettings",
        component: () => import("../components/dashboard/Settings.vue"),
        meta: {
          auth: 1,
          pageMenu: "dashboard",
        },
      },
      //profile
      {
        path: "/dashboard/profile",
        name: "DashboardProfile",
        component: () => import("../components/dashboard/Profile.vue"),
        meta: {
          auth: 1,
          pageMenu: "dashboard",
        },
      },
      //bug
      {
        path: "/dashboard/submit-bug",
        name: "DashboardSubmitBug",
        component: () => import("../components/dashboard/SubmitBug.vue"),
        meta: {
          auth: 1,
          pageMenu: "dashboard",
        },
      },
      //help
      {
        path: "/dashboard/help",
        name: "DashboardHelp",
        component: () => import("../components/dashboard/Help.vue"),
        meta: {
          auth: 1,
          pageMenu: "dashboard",
        },
      },
    ],
  },
  //embed
  {
    path: "/dashboard/playground/:botId/embed",
    name: "UserEmbed",
    component: () => import("../views/Embed.vue"),
    meta: {
      auth: 0,
    },
  },
  {
    path: "*",
    redirect: "/",
  },
  //play-and-share
  {
    path: "/dashboard/playground/:botId/play-and-share",
    name: "UserPlayShare",
    component: () => import("../views/PlayShare.vue"),
    meta: {
      auth: 0,
    },
  },
  //invitation
  {
    path: "/chatbot/:botId/invitation",
    name: "invitation",
    component: () => import("../views/Invitation.vue"),
    meta: {
      auth: 0,
      pageMenu: "invitation",
    },
  },

  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(function (to, from, next) {
  next();
});

export default router;
