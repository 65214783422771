import endpoints from "./endpoints";
import errorHandler from "./errorHandler";

// REQUIREMENTS
// {
//   "username": "", use email
//   "password": "",
// }
export async function login(data) {
  try {
    const url = endpoints.login;
    const response = axios.post(url, data);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function logout() {
  try {
    const url = `${endpoints.logout}`;
    const response = axios.post(url);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function register(data) {
  try {
    const url = endpoints.register;
    const response = axios.post(url, data);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMe() {
  try {
    const url = endpoints.me;
    const response = axios.get(url);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function chat({ prompt, device_id, conversation_id }) {
  try {
    const url = endpoints.chatCompletion;
    const response = axios.post(url, { prompt, device_id, conversation_id });
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getConvoHistory({ conversation_id }) {
  try {
    const url = endpoints.conversationsHistory;
    const response = axios.get(url, { params: { conversation_id } });
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getUserHistory(device_id) {
  try {
    const url = endpoints.conversations;
    const response = axios.get(url, { params: { device_id } });
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteUserHistory(id) {
  try {
    const url = `${endpoints.conversations}/${id}`;
    const response = axios.delete(url);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

//get user chatbots
export async function getBots() {
  try {
    const url = endpoints.userChatbots;
    const response = axios.get(url);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

// create bot
export async function createBot(data) {
  try {
    const url = `${endpoints.chatbot}`;
    const response = axios.post(url, data);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

// get bot by id
export async function getBot(id) {
  try {
    const url = `${endpoints.chatbot}/${id}`;
    const response = axios.get(url);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

//playground
export async function sendChat({ botId, prompt, device_id, conversation_id }) {
  try {
    const url = `${endpoints.sendChat}/${botId}`;
    const response = axios.post(url, {
      botId,
      prompt,
      device_id,
      conversation_id,
    });
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

//settings
export async function updateBot(id, data) {
  try {
    const url = `${endpoints.chatbot}/${data.id}`;
    const response = axios.put(url, data);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deleteBot(id) {
  try {
    const url = `${endpoints.chatbot}/${id}`;
    const response = axios.delete(url);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

//train
export async function trainBot(botId, data) {
  try {
    const url = `${endpoints.chatbot}/store-input/${botId}`;
    const formData = new FormData();

    formData.append("type", data.type);
    formData.append("title", data.title);

    if (data.file) {
      formData.append("file", data.file);
    } else {
      formData.append("question", data.question);
      formData.append("answer", data.answer);
      formData.append("url", data.url);
      formData.append("text", data.text);
    }

    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error in trainBot:", error);
    throw error;
  }
}
//train history
export async function getBotTrain(id, type, page) {
  try {
    const url = `${endpoints.chatbot}/training-history/${id}?type=${type}&page=${page}`;
    const response = axios.get(url, page, type);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

//delete train
export async function deleteTrain(id, data) {
  try {
    const url = `${endpoints.deleteTrain}/chatbot/${id}`;
    const response = axios.delete(url, { data });
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

//update train
export async function updateTrain(botId, data) {
  try {
    const url = `${endpoints.chatbot}/${botId}/uploaded-data/${data.id}/update`;
    const response = axios.put(url, data);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateUser(data) {
  try {
    const url = `${endpoints.user}/update`;
    const response = axios.put(url, data);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

// get user history by bot id
export async function getUserBotHistory(id) {
  try {
    const url = `${endpoints.users}/history/${id}?order_by=desc`;
    const response = axios.get(url);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

// get user history message by history id
export async function getUserBotHistoryMessages(id, convoId) {
  try {
    const url = `${endpoints.users}/history/${id}/conversation/${convoId}?order_by=desc`;
    const response = axios.get(url);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

//bug
export async function createBug(data) {
  try {
    const url = `${endpoints.bug}`;
    const response = axios.post(url, data);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

//notifications
export async function getNotifications() {
  try {
    const url = `${endpoints.notifications}?order_by=desc`;
    const response = axios.get(url);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getUnreadNotifications() {
  try {
    const url = `${endpoints.notifications}?is_read=0`;
    const response = axios.get(url);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

//next page notifications
export async function getNotificationsNextPage(url) {
  try {
    const response = axios.get(url);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

//read user notification
export async function readUserNotification(item_id, data) {
  try {
    const url = `${endpoints.readNotifications}/${item_id}`;
    const response = axios.put(url, data);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

//read all notifications
export async function readAllNotification(item_id, data) {
  try {
    const url = `${endpoints.readNotifications}`;
    const response = axios.put(url, data);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

//contacts
export async function createContact(botId, data) {
  try {
    const url = `${endpoints.contacts}/${botId}`;
    const response = axios.post(url, data);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

//delete contact
export async function deleteContact(type, data) {
  try {
    const url = `${endpoints.deleteContact}/${type}`;
    const response = axios.delete(url, { data });
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

//update toggle conversation
export async function updateConversationToggle(data) {
  try {
    const url = `${endpoints.conversations}/${data.id}/toggle`;
    const response = axios.put(url, data);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

// get roles
export async function getRoles() {
  try {
    const url = `${endpoints.roles}`;

    const response = axios.get(url);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

// invite user to chatbot
export async function sendInvite(data) {
  try {
    const url = `${endpoints.invite}/${data.bot}/to/chatbot`;
    console.log("data:::", data);
    const response = axios.post(url, data);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

// get invite
export async function getInvite(id) {
  try {
    const url = `${endpoints.invite}/${id}`;
    const response = axios.get(url);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function acceptInvite({ code, data }) {
  try {
    const url = `${endpoints.accept}/${data.userId}/invite/${code}`;
    const response = axios.post(url, data);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

// delegated accounts
export async function getDelegatedAccounts(id) {
  try {
    const url = `${endpoints.delegated}/${id}`;
    const response = axios.get(url);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getConversationId({ chatbot_id, params }) {
  try {
    const url = `${endpoints.conversation}/${chatbot_id}`;
    const response = axios.get(url, { params });
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function openAuthFacebook() {
  try {
    const url = `${endpoints.facebook}/redirect`;
    const response = axios.get(url);
    response.catch((err) => {
      errorHandler(err);
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}
