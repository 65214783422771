<template>
  <div>
    <v-dialog
      v-model="show"
      content-class="modal-training-history-component"
      width="650"
      height="700"
      persistent
    >
      <v-card>
        <v-card :elevation="4" tile>
          <v-card-title class="pa-2 d-flex justify-space-between">
            <span class="title font-weight-bold"
              >Train with {{ type }} history</span
            >
            <v-btn icon @click="show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>

        <v-card-text class="pa-0 relative">
          <div style="max-height: 80vh; overflow-y: auto" class="px-6">
            <LoadingComponent v-if="isLoading" overlay overlayLoadingCenter />
            <div class="py-6" v-if="history.length">
              <div
                class="font-weight-medium d-flex align-center"
                v-if="activeTypeHistory == 'file' || activeTypeHistory == 'url'"
              >
                <v-icon size="30" color="primary" class="mr-2">
                  mdi-information-outline
                </v-icon>
                <h4>
                  You can only edit the title for type
                  <span class="primary--text">FILE</span> and
                  <span class="primary--text">URL</span>.
                </h4>
              </div>

              <!-- Checkbox for Selecting All Items -->

              <div class="d-flex align-center justify-space-between">
                <v-checkbox
                  hide-details
                  v-model="selectAll"
                  label="Select All"
                  @change="toggleSelectAll"
                  class="font-weight-medium"
                />
                <div class="action-btn">
                  <div class="d-flex align-center">
                    <!-- <v-icon
                      :color="
                        selectedItems && selectedItems.length == 1
                          ? 'primary'
                          : '#00000042'
                      "
                      @click="editTrainItem"
                      >mdi-pencil-outline</v-icon
                    > -->

                    <v-icon
                      :color="
                        isDeleting || selectedItems.length === 0
                          ? '#00000042'
                          : 'secondary'
                      "
                      @click="removeTrainItem"
                      >mdi-delete-outline</v-icon
                    >
                  </div>
                </div>
              </div>

              <v-divider></v-divider>
              <v-list dense>
                <v-list-item-group v-model="selectedItems" multiple>
                  <v-list-item
                    v-for="(item, index) in history"
                    :key="index"
                    :ripple="false"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" />
                      </v-list-item-action>
                      <!-- qna -->
                      <div
                        class="question-and-answer-type pr-6 w-100"
                        v-if="activeTypeHistory == 'qna'"
                      >
                        <div class="d-flex justify-space-between">
                          <h4 class="primary--text">Question:</h4>
                          <v-list-item-content
                            class="
                              quaternary--text
                              font-weight-medium
                              ml-2
                              pa-0
                            "
                          >
                            {{ item.question }}
                          </v-list-item-content>
                        </div>

                        <div class="d-flex justify-space-between">
                          <h4 class="secondary--text">Answer:</h4>
                          <v-list-item-content
                            class="
                              quaternary--text
                              font-weight-medium
                              ml-2
                              pa-0
                            "
                          >
                            {{ item.answer }}
                          </v-list-item-content>
                        </div>
                        <span style="font-size: 10px">{{
                          item.updated_at | formattedDate
                        }}</span>
                        <v-divider class="my-2"></v-divider>
                      </div>

                      <!-- text -->
                      <div
                        class="question-and-answer-type pr-6 w-100"
                        v-if="activeTypeHistory == 'text'"
                      >
                        <div class="d-flex justify-space-between">
                          <h4 class="primary--text">Title:</h4>
                          <v-list-item-content
                            class="
                              quaternary--text
                              font-weight-medium
                              ml-2
                              pa-0
                              truncate-3
                            "
                          >
                            {{ item.title }}
                          </v-list-item-content>
                        </div>
                        <div>
                          <v-list-item-content
                            class="quaternary--text font-weight-medium pb-0"
                          >
                            {{ item.object }}
                          </v-list-item-content>
                          <span style="font-size: 10px">{{
                            item.updated_at | formattedDate
                          }}</span>
                        </div>

                        <v-divider class="my-2"></v-divider>
                      </div>

                      <!-- url -->
                      <div
                        class="question-and-answer-type pr-6 w-100"
                        v-if="activeTypeHistory == 'url'"
                      >
                        <div class="d-flex justify-space-between">
                          <h4 class="primary--text">Title:</h4>
                          <v-list-item-content
                            class="
                              quaternary--text
                              font-weight-medium
                              ml-2
                              pa-0
                            "
                          >
                            {{ item.title }}
                          </v-list-item-content>
                        </div>

                        <div>
                          <v-list-item-content
                            class="
                              quaternary--text
                              font-weight-medium
                              pb-0
                              pt-0
                            "
                          >
                            {{ item.object }}
                          </v-list-item-content>
                          <span style="font-size: 10px">{{
                            item.updated_at | formattedDate
                          }}</span>
                        </div>
                        <v-divider class="my-2"></v-divider>
                      </div>

                      <!-- file -->
                      <div
                        class="question-and-answer-type pr-6 w-100"
                        v-if="activeTypeHistory == 'file'"
                      >
                        <div class="d-flex justify-space-between">
                          <h4 class="primary--text">Title:</h4>
                          <v-list-item-content
                            class="
                              quaternary--text
                              font-weight-medium
                              ml-2
                              pa-0
                            "
                          >
                            {{ item.title }}
                          </v-list-item-content>
                        </div>

                        <div>
                          <v-list-item-content
                            class="
                              quaternary--text
                              font-weight-medium
                              pb-0
                              pt-0
                            "
                          >
                            {{ item.object }}
                          </v-list-item-content>
                          <span style="font-size: 10px">{{
                            item.updated_at | formattedDate
                          }}</span>
                        </div>

                        <v-divider class="my-2"></v-divider>
                      </div>
                      <div class="d-flex">
                        <v-icon
                          v-if="item.type == 'url'"
                          class="mx-1"
                          color=""
                          @click.stop="openUrl(item)"
                          >mdi-open-in-new</v-icon
                        >

                        <v-icon
                          class="mx-1"
                          color="primary"
                          @click.stop="editTrain(item)"
                          >mdi-pencil-outline</v-icon
                        >
                        <v-icon
                          class="mx-1"
                          color="secondary"
                          @click.stop="removeTrain(item)"
                          >mdi-delete-outline</v-icon
                        >
                      </div>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
            <!-- Pagination Controls -->
            <div
              class="d-flex justify-center mb-6"
              v-if="historyPagination.last_page > 1"
            >
              <v-pagination
                v-if="historyPagination.last_page > 1"
                v-model="page"
                :length="historyPagination.last_page"
                :total-visible="getPaginationLength"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              ></v-pagination>
            </div>
            <div
              v-if="!history.length"
              class="w-100 d-flex justify-center align-center"
              style="height: 200px"
            >
              <div class="text-center">
                <p class="mb-2 font-weight-bold">No data available.</p>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import LoadingComponent from "@/components/helpers/Loading.vue";
import Utils from "@/helpers/utils";

export default {
  components: {
    LoadingComponent,
  },

  data() {
    return {
      isLoading: false,
      isDeleting: false,
      selectAll: false,
      selectedItems: [],
      selectedItemsData: [],

      page: 1,
      prevPage: 0,
      currentPage: 1,
      apiData: {},
    };
  },

  methods: {
    ...mapMutations("modal", ["setShowTrainingHistory"]),
    ...mapMutations("train", ["REMOVE_TRAIN"]),
    ...mapActions("train", ["getBotTrain", "deleteTrain"]),

    initTrainHistories(page = 1) {
      this.isLoading = true;

      let _params = {
        id: this.activeBot.id,
        type: this.activeTypeHistory,
        page: page,
      };

      this.getBotTrain(_params)
        .then((res) => {})
        .finally(() => (this.isLoading = false));
    },

    editTrainItem() {
      globalComingSoonHandler(
        "New features and enhancements coming soon!",
        "Coming Soon"
      );
    },
    editTrain(item) {
      this.show = false;
      BroadcastEvent.$emit("TRAIN_EDIT", item);
    },
    openUrl(item) {
      const url = item.object;
      window.open(url, "_blank");
    },
    removeTrain(item) {
      Utils.showMessageDefault({
        html: `Are you sure you want to remove?`,
      }).then((res) => {
        if (res.isConfirmed) {
          let _data = {
            uploaded_data_id: [item.id],
          };

          this.deleteTrainData({
            botId: this.activeBot.id,
            _data,
          });
        }
      });
    },
    removeTrainItem() {
      Utils.showMessageDefault({
        html: `Are you sure you want to remove?`,
      }).then((res) => {
        if (res.isConfirmed) {
          let _data = {
            uploaded_data_id: this.selectedItemsData.map((i) => i.id),
          };
          this.deleteTrainData({
            botId: this.activeBot.id,
            _data,
          });
        }
      });
    },
    async deleteTrainData(form) {
      try {
        this.isLoadingDelete = true;
        await this.deleteTrain(form)
          .then(
            (res) => {
              globalMessageHandler("Successfully Deleted.");
              this.REMOVE_TRAIN(form._data.uploaded_data_id);
              this.selectedItems = [];
              this.selectedItemsData = [];
              this.isLoadingDelete = false;
              this.isLoadingDelete = true;
            },
            (err) => {
              globalErrorHandler(err, "Error deleting train!");
            }
          )
          .finally(() => {
            this.isLoadingDelete = true;
          });
      } catch (error) {
        globalErrorHandler(error, "Unable to delete train.");
      } finally {
        this.isLoadingDelete = true;
      }
    },

    toggleSelectAll() {
      if (this.selectAll) {
        let i = 0;
        this.history.forEach((item) => {
          this.selectedItems.push(i);
          i++;
        });
      } else {
        this.selectedItems = [];
      }
    },
    isSelected(item) {
      this.selectedItemsData = [];
      this.selectedItems.forEach((selectedItem) => {
        let index = _.findIndex(this.history, {
          id: this.history[selectedItem].id,
        });
        if (index >= 0)
          this.selectedItemsData.unshift(this.history[selectedItem]);
      });

      if (this.history.length > this.selectedItems.length) {
        this.selectAll = false;
      } else {
        this.selectAll = true;
      }
    },
  },

  mounted() {
    this.initTrainHistories();
  },
  filters: {
    formattedDate(val) {
      // return moment(new Date(val)).format("MMMM D, YYYY");
      return moment(new Date(val)).format("l");
    },
  },
  computed: {
    ...mapGetters("modal", ["showTrainingHistory"]),
    ...mapGetters("train", ["activeTrainHistory", "activeTypeHistory"]),
    ...mapGetters("bots", ["activeBot"]),

    show: {
      get() {
        return this.showTrainingHistory;
      },
      set(v) {
        this.setShowTrainingHistory(v);
      },
    },

    // history() {
    //   const _startIndex =
    //     (this.currentPage - 1) * this.historyPagination.per_page;
    //   const _endIndex = _startIndex + this.historyPagination.per_page;
    //   return this.activeTrainHistory.data.slice(_startIndex, _endIndex);

    //   // return this.activeTrainHistory.data;
    // },
    history() {
      const _startIndex =
        (this.currentPage - 1) * this.historyPagination.per_page;
      const _endIndex = _startIndex + this.historyPagination.per_page;

      // Use filter to exclude items with both question and answer as "null"
      return this.activeTrainHistory.data
        .filter((item) => item.question !== "null" || item.answer !== "null")
        .slice(_startIndex, _endIndex);
    },

    historyPagination() {
      return this.activeTrainHistory;
    },

    type() {
      if (this.activeTypeHistory == "qna") {
        return "Chat";
      } else if (this.activeTypeHistory == "text") {
        return "Text";
      } else if (this.activeTypeHistory == "url") {
        return "Url";
      } else {
        return "File";
      }
    },

    getPaginationLength() {
      if (this.$vuetify.breakpoint.xl) {
        return 5;
      } else if (this.$vuetify.breakpoint.lg) {
        return 4;
      } else if (this.$vuetify.breakpoint.xs) {
        return 4;
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return 3;
      }
      return 3;
    },
  },

  watch: {
    page: {
      immediate: false,
      handler(val, oldVal) {
        this.prevPage = oldVal;
        this.initTrainHistories(val);
        if (this.selectedItems.length === 0) {
          this.selectedItems = [];
        }
      },
    },
    selectedItems: {
      immediate: false,
      handler(val) {
        this.isSelected(val);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-training-history-component {
  max-height: 100% !important;
}
</style>
