import {
  getBotTrain,
  trainBot,
  deleteTrain,
  updateBot,
  updateTrain,
} from "@/routes";

export default {
  namespaced: true,
  state: {
    activeTrainHistory: {},
    activeTypeHistory: null,
  },
  getters: {
    activeTypeHistory: (state) => state.activeTypeHistory,
    activeTrainHistory: (state) => state.activeTrainHistory,
  },
  mutations: {
    setActiveTypeHistory(state, payload) {
      state.activeTypeHistory = payload;
    },
    setActiveTrainHistory(state, payload) {
      state.activeTrainHistory = payload;
    },
    REMOVE_TRAIN(state, ids) {
      ids.forEach((id) => {
        const _index = _.findIndex(state.activeTrainHistory.data, (item) => {
          return item.id == id;
        });

        if (_index > -1) state.activeTrainHistory.data.splice(_index, 1);
      });
    },
  },

  actions: {
    createTrain({}, { botId, data }) {
      return trainBot(botId, data);
    },

    getBotTrain({ commit }, { id, type, page }) {
      return getBotTrain(id, type, page).then((res) => {
        commit("setActiveTrainHistory", res.data);
      });
    },

    deleteTrain({}, { botId, _data }) {
      return deleteTrain(botId, _data).then((res) => {});
    },

    updateTrainItem({}, { botId, data }) {
      return updateTrain(botId, data);
    },
  },
};
