<template>
  <v-app id="app" class="app-main-page" style="overflow-x: hidden">
    <div v-if="isLoading">
      <LoadingComponent class="mt-4" text="Processing..." />
    </div>
    <template v-else>
      <ModalHolder />
      <NotificationAlert ref="alert" />
      <router-view />
      <LoadingComponent v-if="false" />
    </template>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import auth from "@/helpers/auth";
import LoadingComponent from "@/components/helpers/Loading";
import ModalHolder from "@/components/modals/ModalHolder";
import constants from "@/helpers/constants";
import NotificationAlert from "@/components/helpers/NotificationAlert";
import Pusher from "pusher-js";

export default {
  components: {
    LoadingComponent,
    ModalHolder,
    NotificationAlert,
  },

  created() {
    document.title = "PinoyBot";
  },

  data() {
    return {
      isLoading: false,
      pusherData: null,
      snackbarData: [],
    };
  },

  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("bots", ["bots", "activeBot"]),

    currentRoute() {
      return this.$route.fullPath;
    },
    myOrigin() {
      return window.location.origin;
    },
    isReady() {
      return {
        user: this.currentUser,
        route: this.$route,
      };
    },
    getCurrentRoute() {
      return this.$route.name;
    },
  },

  mounted() {
    if (auth.hasToken() && !this.getAuth) {
      this.getCurrentUser();
      this.getRoles().finally(() => {
        console.log("roles load");
      });
    }
    this.logUserDevice();
    if (this.currentUser) {
      this.getUnreadBotNotifications();
      this.getBotNotifications();
    }
    this.pusherListen();
  },

  methods: {
    ...mapActions("auth", ["getCurrentUser"]),
    ...mapActions("notifications", [
      "getNotifications",
      "getUnreadNotifications",
    ]),
    ...mapActions("user", ["getRoles", "getDelegatedAccounts"]),
    ...mapMutations("notifications", ["setNotificationCount"]),
    async logUserDevice() {
      const _deviceId = await window.generateUUID();
      const _localDeviceId = await localStorage.getItem(
        constants.PINOYCHATGPT_DEVICE_ID
      );
      if (!_localDeviceId) {
        localStorage.setItem(constants.PINOYCHATGPT_DEVICE_ID, _deviceId);
      }
    },
    async getBotNotifications() {
      try {
        await this.getNotifications();
      } catch {
        console.log("Unable to fetch notification2.");
      } finally {
      }
    },
    async getUnreadBotNotifications() {
      try {
        await this.getUnreadNotifications().then(
          (res) => {},
          (err) => {
            console.log({ resxzxfc: err });
          }
        );
      } catch {
        console.log("Unable to fetch notification2.");
      } finally {
      }
    },
    pusherListen() {
      var pusher = new Pusher("aab52b6069e73a92ba7c", {
        cluster: "ap1",
      });

      var channel = pusher.subscribe(`${this.currentUser.id}-user-query`);

      channel.bind("user-query", (data) => {
        console.log("notif", data);
        this.$refs["alert"].showSnackbar(
          data.message,
          data.from?.full_name,
          data.conversation_link,
          "white"
        );

        //multi-snackbars
        // this.snackbarData.push({
        //   message: data.message,
        //   full_name: data.from?.full_name,
        //   type: "white",
        // });

        //display all
        // this.snackbarData.forEach((snackbar) => {
        //   this.$refs["alert"].showSnackbar(
        //     snackbar.message,
        //     snackbar.full_name,
        //     "white"
        //   );
        // });
      });
    },
  },

  watch: {
    isReady(val) {
      if (val.user && val.route) {
        this.getUnreadBotNotifications();
        this.getBotNotifications();
        if (
          val.route.name != "UserPlayShare" &&
          val.route.name != "UserEmbed" &&
          val.route.name != "DashboardPlayground"
        ) {
          this.pusherListen();
        }
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #f0f2f5;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.app-main-page {
  // overflow: hidden;
  overflow-y: auto;
  position: relative;
  height: 100vh;
  position: relative;
}
</style>
