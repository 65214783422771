export default {
  namespaced: true,
  state: {
    showAbout: false,
    showTrainingHistory: false,
    showCreateBot: false,
    showSample: false,
    activeSample: null,
  },
  getters: {
    showAbout: (state) => state.showAbout,
    showTrainingHistory: (state) => state.showTrainingHistory,
    showCreateBot: (state) => state.showCreateBot,
    showSample: (state) => state.showSample,
    activeSample: (state) => state.activeSample,
  },
  mutations: {
    setShowAbout(state, data) {
      state.showAbout = data;
    },
    setShowTrainingHistory(state, data) {
      state.showTrainingHistory = data;
    },
    setShowCreateBot(state, data) {
      state.showCreateBot = data;
    },
    setShowSample(state, data) {
      state.showSample = data;
    },
    setActiveSample(state, payload) {
      state.activeSample = payload;
    },
  },
  actions: {},
};
